/**
 * Copyright © 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#firebaseui_container {
  width: 100%;
}

.dai-firebase #firebaseui-container {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: none;
}

/* TODO: Figure out how to style the progress bar */
#firebaseui_container
  > div
  > div.mdl-progress.firebaseui-id-busy-indicatory.firebaseui-busy-indicator {
  display: none !important;
}

.dai-firebase .firebaseui-relative-wrapper {
  display: flex;
  padding: 15px;
  flex-direction: column;
}
#firebaseui_container > .mdl-card {
  box-shadow: none;
}
.dai-firebase .mdl-button:focus:not(:active) {
  background: none;
}

.dai-firebase .firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.dai-firebase .firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.dai-firebase .firebaseui-card-header {
  display: none;
}
.dai-firebase .firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}

.dai-firebase .firebaseui-button {
  height: 36px;
  border-radius: 18px;
  box-shadow: none;
}

.dai-firebase .firebaseui-button.mdl-button--primary:hover {
  background: none;
}

.dai-firebase .firebaseui-button:hover {
  box-shadow: rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.2) 0 3px 1px -2px,
    rgba(0, 0, 0, 0.12) 0 1px 5px 0;
}

.dai-firebase
  .firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: #e2000f;
}

.dai-firebase .firebaseui-form-actions .mdl-button--primary.firebaseui-button {
  /*border: solid 1px #E2000F;*/
}

.dai-firebase .firebaseui-id-dismiss-info-bar {
  display: block;
}
.dai-firebase .firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}

.dai-firebase .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  display: none;
  background-color: #e2000f;
}

.dai-firebase .mdl-button--primary.mdl-button--primary {
  color: #e2000f;
}

.dai-firebase div.firebaseui-textfield {
  flex-grow: 1;
}

.dai-firebase div.firebaseui-textfield > label {
  padding-left: 18px;
  padding-top: 4px;
}

.dai-firebase div.firebaseui-textfield.is-focused > label {
  padding-top: 0;
}

.dai-firebase div.firebaseui-textfield.is-dirty > label {
  padding-top: 0;
}

#firebaseui_container
  > div
  > form
  > div.firebaseui-card-content
  > div
  > div.firebaseui-textfield.mdl-textfield.mdl-js-textfield.mdl-textfield--floating-label.is-upgraded.is-focused
  > label {
  padding-top: 0;
}

.dai-firebase div.firebaseui-textfield > input {
  height: 36px;
  border: solid 1px #e0e0e0;
  border-radius: 18px;
  padding-left: 18px;
}

.dai-firebase div.firebaseui-new-password-component > a {
  margin-right: 8px;
  margin-top: 6px;
}

.dai-firebase .firebaseui-error-wrapper {
  padding-left: 18px;
}

.dai-firebase .firebaseui-card-content {
  padding: 0;
}
